.app-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 90%;
  background-color: #c1d9f3;
}

.login-view {
  display: flex;
  flex-direction: column;
  max-width: 760px;
  margin: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  align-items: center;
}
.login-form {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  width: 80%;
  padding: 10px;
  margin-bottom: 30px;
  background: #fff;
  align-self: center;
  align-items: center;
  justify-content: space-around;
}

.login-text-input {
  width: 80%;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-self: center;
}

.login-button {
  width: 80%;
  padding: 10px;
  margin: 10px;
  background: #0069d9;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.loginout {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
}

.allocate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 15px;
}

.allocate-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px;
  justify-content: space-around;
  align-items: center;
}

.amount-for-allocation {
  font-weight: 400;
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
  padding-left: 15px;
  width: 70%;
}

.grid-container {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: auto;
}

.grid-item {
  width: 90%;
  align-items: center;
  justify-content: center;
}

.input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  margin: 15px;
  overflow-y: scroll;
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

table {
  border-collapse: collapse;
  max-height: 300px;
  overflow-y: scroll;
  width: 95%;
}

th,
td {
  display: table-cell;
  flex: 1;
  border: 1px solid black;
  padding: 4px;
  text-align: center;
  font-weight: 300;
  font-size: .8rem;
}

thead {
  background: #0069d9;
}

thead th {
  color: white;
  top: 0;
  z-index: 2;
}

tbody td {
  color: black;
  background-color: white;
}

.container {
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: space-around;
  align-content: center;
}

.left {
  flex: 1;
}

.centered {
  flex: 1;
  text-align: center;
}

.right {
  flex: 1;
  text-align: right;
}

.right-foot {
  align-items: end;
  margin-top: 10px;
  font-size: .6rem;
  font-style: italic;
}

.container-locates {
  display: flex;
  width: 100%;
  margin: 15px;
  justify-content: space-around;
  align-items: center;
}

.left-locates {
  width: 100%;
  align-self: left;
}

.centered-locates {
  width: auto;
  min-width: 10px;
  align-self: center;
}

.right-locates {
  width: 100%;
  align-self: end;
}

.login-logo {
  width: 100px;
  margin-top: 30px;
  object-fit: contain;
}

.textareaAutosize {
  flex: 1;
}

.textField {
  flex: 1;
}